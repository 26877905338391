import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { Link } from 'react-scroll';

const Menu = (props) => {
  const { menuLinks } = props.data.site.siteMetadata;
  return (
    <div id="main-menu" className="main-menu">
      <ul>
        {menuLinks.map(link => (
          <li key={link.name}>
            <Link
              activeClass="active"
              to={link.key}
              spy={true}
              smooth={true}
              duration={500}
              offset={-60}>
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
              key
            }
          }
        }
      }
    `}
    render={data => <Menu data={data} />}
  />
);
