import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { Link } from 'react-scroll';

const MenuMobile = props => {
  const { menuLinks } = props.data.site.siteMetadata;
  return (
    <div
      id="main-menu-mobile"
      className={`main-menu-mobile ${props.active ? 'open' : ''}`}
    >
      <ul>
        {menuLinks.map(link => (
          <li key={link.name}>
            <Link
              href={link.link}
              activeClass="active"
              to={link.key}
              spy={true}
              smooth={true}
              duration={500}
              offset={-60}>
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query MenuMobileQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
              key
            }
          }
        }
      }
    `}
    render={data => <MenuMobile active={props.active} data={data} />}
  />
);
