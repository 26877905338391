import React from 'react';
import Layout from '../layouts/index';
import About from './section/about';
import ComingSoon from './section/comingsoon';
import HomeSection from './section/home';
import OurPlatform from './section/ourplatform';
import OurWater from './section/ourwater';
import Why from './section/why';

const HomePage = () => {
  return (
    <Layout bodyClass="page-home">
      <HomeSection />
      <About />
      <Why />
      <OurWater />
      <OurPlatform />
      <ComingSoon />
    </Layout>
  );
};

export default HomePage;
