import React from 'react';
import {graphql, Link, StaticQuery} from 'gatsby';
import instagramIcon from '../images/instagram_icon.png';

const Footer = (props) => (
  <div className="footer-strip">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="footer">
            <h3 className="footer-title">{props.data.site.siteMetadata.title}</h3>
            <ul className="footer-menu">
              <li className='flex-1'>
                {' '}
                <Link className='footer-instagram-container' to="https://www.instagram.com/izifill/" target='_blank'>
                  <img
                    className="footer-instagram"
                    src={instagramIcon} alt="Instagram Icon" />
                  <p className="my-0 w-100 flex-1">Instagram</p>
                </Link>
              </li>
              <li className="copyright">
                ©
                {' '}
                {new Date().getFullYear()}
                {' '}
                {props.data.site.siteMetadata.title}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => <Footer data={data} />}
  />
);
