import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const HomeSection = (props) => {
  const backgroundImage = props.data.background.childImageSharp.fluid;
  return (
    <BackgroundImage
      id="section-home"
      Tag="section"
      className="section-home w-100 d-flex align-items-center"
      fluid={backgroundImage}
    >
      <div className="container section-home-container d-flex justify-content-between my-4">
        <div className="align-self-center d-flex flex-column">
          <h1 className=" bold section-home-headline">Bring your tumbler everyday,</h1>
          <h1 className=" bold section-home-headline">we take care the rest</h1>
          <p style={{wordWrap: 'break-word'}}>
            Saving our planet by reducing plastic waste can be started by a simple action: bring your tumbler.
            IZIFILL help you to provide clean and fresh water so your tumbler keep refilled without direct touch to the water station
          </p>
        </div>
        <iframe className='section-home-video flex-1' src="https://www.youtube.com/embed/TIEp022DKmo"
          title="IZIFILL" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen>
        </iframe>
        {/* <Img className="section-home-img align-self-center" fluid={motherEarth} /> */}
      </div>
    </BackgroundImage>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        background: file(relativePath: { eq: "background_carousel.jpg" }) {
          childImageSharp {
            fluid (quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        motherEarth: file(relativePath: { eq: "mother_earth.png" }) {
          childImageSharp {
            fluid (quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
  `}
    render={(data) => <HomeSection data={data} />}
  />
);
