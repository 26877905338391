import React, {useState, useEffect} from 'react';
import Menu from './Menu';
import Hamburger from './Hamburger';
import logo from '../images/logo.png';
import logoMobile from '../images/logo-small.png';
import MenuMobile from './MenuMobile';
import {Link as Link2} from 'react-scroll';

function Header() {
  const [menuActive, setMenuActive] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {passive: true});

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`header ${scrollPosition > 0 ? 'header-shadow' : ''}`}>
      <div className="container">
        <div className="logo">
          <Link2
            activeClass="active"
            to="section-home"
            smooth={true}
            spy={true}
            duration={500}
            offset={-60}>
            <img alt="Figurit Homepage" src={logo} />
          </Link2>
        </div>
        <div className="logo-mobile">
          <Link2
            activeClass="active"
            to="section-home"
            smooth={true}
            spy={true}
            duration={500}
            offset={-60}>
            <img alt="Figurit Homepage" src={logoMobile} />
          </Link2>
        </div>
        <MenuMobile active={menuActive} />
        <Menu />
        <Hamburger toggleMenu={() => setMenuActive(!menuActive)} />
      </div>
    </div>
  );
}

export default Header;
